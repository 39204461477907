
const AuthContent = ({
    
}) => {
    return (
        <div>
            <h1>Try Login</h1>
        </div>
    );
}

export default AuthContent;