import React, { useState } from 'react';

import './App.css';
import Login from './components/Login';
import AuthContent from './components/AuthContent';
import NonAuthContent from './components/NonAuthContent';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  return (
    <div>
      <Login
        setIsAuthenticated={setIsAuthenticated}
      />
      {isAuthenticated ? (
        <AuthContent/>
      ) : (
        <NonAuthContent/>
      )}
    </div>
  );
}

export default App;
