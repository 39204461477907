import React from 'react';
import * as api from '../api/api';

const AuthContent = () => {
    // const [content, setContent] = React.useState('Loading...');

    // api.get().then((response) => {
    //     console.log(response);
    //     setContent(response.data);
    // });
    if("serviceWorker" in navigator){
        navigator.serviceWorker.register("ServiceWorker.js").then(registration=>{
        console.log("SW Registered!");
        }).catch(error=>{
        console.log("SW Registration Failed");
        });
    } else {
        console.log("Not supported");
    }

    const getTimeWithUnit = (last_updated) => {
        const last_updated_date = new Date(last_updated);
        const milliseconds = Date.now() - last_updated_date;
        const seconds = milliseconds / 1000;
        let time, unit;
        if (seconds < 2) {
            return " Just now";
        } else if (seconds < 60) {
            time = seconds;
            unit = "s";
        } else if (seconds < 3600) {
            time = seconds / 60
            unit = " min";
        } else if (seconds < 3600 * 24) {
            time = seconds / 3600
            unit = " hr";
        } else if (seconds < 3600 * 24 * 7) {
            time = seconds / (3600 * 24)
            unit = " day";
        } else {
            return " " + last_updated_date.toLocaleDateString();
        }
        return " " + Math.floor(time).toString() + 
            unit + 
            ((time >= 2 && unit !== "s") ? "s" : "") + " ago";
    }

    const updateProgressState = (progresses, last_updated, firstRun = false) => {
        const lastUpdatedHtml = document.getElementById("last_updated");
        if (lastUpdatedHtml !== null) {
            if (last_updated === 0) {
                lastUpdatedHtml.innerHTML = "Never";
            } else {
                lastUpdatedHtml.innerHTML = getTimeWithUnit(last_updated);
            }

            const refreshButton = document.getElementById("refresh_button");
            if (!firstRun && refreshButton !== null) {
                refreshButton.value = "Refresh";
                refreshButton.disabled = false;
            }
            const refreshAllButton = document.getElementById("refresh_all_button");
            if (!firstRun && refreshAllButton !== null) {
                refreshAllButton.value = "Refresh All";
                refreshAllButton.disabled = false;
            }
            document.getElementById("most_recent_activity_date").innerHTML = progresses.mostRecentActivityDate;
            for (const index in progresses.goalsProgress) {
                const goalProgress = progresses.goalsProgress[index];
                const progressElementId = "progress_" + index;
                const progressHtml = getProgressHtml(
                    goalProgress
                );
                document.getElementById(progressElementId).innerHTML = progressHtml
            }
        }
    }

    const getProgressHtml = (progress) => {
        const numberWithCommas = (num) => {
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        const valueWithUnit = (value, unit) => {
            let proccessedUnit = unit;
            let proccessedValue = value ?? "0";
            if (proccessedUnit === "hours") {
                if (value < 1) {
                    proccessedValue = Math.round(value * 60, 1);
                    proccessedUnit = "minutes";
                }
            }
            return numberWithCommas(proccessedValue) + " " + proccessedUnit;
        }
        let unit = (progress?.unit) ?? "";
        let totalsText = ""
        if (progress.activityTypes.length > 0) {
            for (let i = 0; i < progress.activityTypes.length; i++) {
                totalsText += progress.activityTypes[i] + ": " + 
                valueWithUnit(progress.progressByActivityType[i], unit) + "\n"
            }
        }
        return "Goal: " + (progress?.goalName ?? "") + "\n" + 
            "Goal Category: " + (progress?.goalCategoryName ?? "") + "\n" +
            "Start Date: " + (progress?.startDate ?? "") + "\n" +
            "End Date: " + (progress?.endDate ?? "") + "\n" +
            totalsText + 
            "Target: " + valueWithUnit(progress?.goalValue, unit) + "\n" +
            "Target average per day: " + valueWithUnit(progress?.targetAverage, unit) + "\n" +
            "So far: " + valueWithUnit(progress?.totalProgress, unit) + ", " + (progress?.percentageDone ?? "") + "%\n" +
            "To go: " + valueWithUnit(progress?.left, unit) + ", " + (progress?.percentageLeft ?? "") + "%\n" +
            "New average per day: " + valueWithUnit(progress?.newAverage, unit) + "\n" +
            "Pace difference: " + valueWithUnit(progress?.paceDifference, unit) + "\n" +
            "Percentage pace difference: " + (progress?.percentagePaceDifference ?? "") + "%"
    }

    const getProgressFromServer = () => {
        api.getProgress()
            .then(({data}) => {
                const progresses = data ?? "Failed to Load";
                const last_updated = Date.now();
                localStorage.setItem('progress', JSON.stringify({
                    'progresses': progresses,
                    'last_updated': last_updated,
                }));
                updateProgressState(progresses, last_updated)
            });
        const refresh_button = document.getElementById("refresh_button");
        if (refresh_button !== null) {
            refresh_button.value = "Refreshing";
            refresh_button.disabled = true;
        }
        const refresh_all_button = document.getElementById("refresh_all_button");
        if (refresh_all_button !== null) {
            refresh_all_button.value = "Refreshing";
            refresh_all_button.disabled = true;
        }
    }

    const getAllProgressFromServer = () => {
        api.getAllProgress()
            .then(({data}) => {
                const progresses = data ?? "Failed to Load";
                const last_updated = Date.now()
                localStorage.setItem('progress', JSON.stringify({
                    'progresses': progresses,
                    'last_updated': last_updated
                }));
                updateProgressState(progresses, last_updated)
            });


            const refresh_button = document.getElementById("refresh_button");
            if (refresh_button !== null) {
                refresh_button.value = "Refreshing All";
                refresh_button.disabled = true;
            }
            const refresh_all_button = document.getElementById("refresh_all_button");
            if (refresh_all_button !== null) {
                refresh_all_button.value = "Refreshing All";
                refresh_all_button.disabled = true;
            }
    }

    const getProgress = (firstRun = false) => {
        let progress;
        try {
            progress = JSON.parse(localStorage.getItem('progress'));
        } catch (e) {
            progress = null;
        }
        const progresses = progress?.progresses ?? 'Loading';
        const last_updated = progress?.last_updated ?? 0;
        updateProgressState(progresses, last_updated, firstRun);
        const thresholdInMilliseconds = 60 * 60 * 1000;
        const now = Date.now()
        const refreshButton = document.getElementById("refresh_button");
        let refreshButtonValue = "Refresh";
        if (!firstRun && refreshButton !== null) {
            refreshButtonValue = refreshButton.value;
        }
        if (refreshButtonValue !== "Refreshing" && firstRun && now - last_updated >= thresholdInMilliseconds) {
            getProgressFromServer();
        }
    }

    getProgress(true);
    setInterval(() => getProgress(true), 1 * 1000);
    setInterval(getProgress, 30 * 1000);

    return (
        <div>
            {/* <link rel="manifest" href="manifest.json"> */}
            <h2>
                <a href="{{ url_for('home') }}">Home</a>
            </h2>
            <input style={{fontSize: "28px", marginBottom: "12px"}}  id="refresh_button" type="button" value="Refresh" onClick={getProgressFromServer}/>
            <input style={{fontSize: "28px", marginBottom: "12px"}}  id="refresh_all_button" type="button" value="Refresh All" onClick={getAllProgressFromServer}/>
            <br/>
            <div style={{fontSize: "26px"}}>
                Last Activity Date: <span style={{fontSize: "20px"}} id="most_recent_activity_date"></span>
            </div>
            <span style={{fontSize: "26px"}}>
                Last Updated: 
                <span style={{fontSize: "20px"}} id="last_updated"></span>
            </span>
            <p id="progress_0" style={{whiteSpace: "pre-wrap"}}/>
            <p id="progress_1" style={{whiteSpace: "pre-wrap"}}/>
            <p id="progress_2" style={{whiteSpace: "pre-wrap"}}/>
            <p id="progress_3" style={{whiteSpace: "pre-wrap"}}/>
            <p id="progress_4" style={{whiteSpace: "pre-wrap"}}/>
            <p id="progress_5" style={{whiteSpace: "pre-wrap"}}/>
            <p id="progress_6" style={{whiteSpace: "pre-wrap"}}/>
            <p id="progress_7" style={{whiteSpace: "pre-wrap"}}/>
            <p id="progress_8" style={{whiteSpace: "pre-wrap"}}/>
            <p id="progress_9" style={{whiteSpace: "pre-wrap"}}/>
        </div>
    );
}

export default AuthContent;